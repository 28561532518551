import { useSelector } from 'react-redux';
import { Alert } from '../../Helpers/Functios';
import React, { Fragment, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { userSelector } from '../../store/modules/login/selectors';
import { logout, apiFetchPut } from '../../Helpers/RequestResponse';

import {
  Avatar,
  Box,
  Menu,
  Button,
  List,
  ListItem,
  Tooltip,
  Divider
} from '@material-ui/core';
import { StyledBadge } from './styled';
import ModalPassword from './ModalPassword';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const defaultValues = {
  password: '',
  oldPassword: ''
};

export default function HeaderUserbox() {
  const user = useSelector(userSelector);

  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [form, setForm] = useState(defaultValues);
  const [openModal, setOpenModel] = useState(false);

  const handleLogout = () => logout();
  const handleClose = () => setAnchorEl(null);
  const handleOpenModal = () => setOpenModel(true);
  const handleClick = event => setAnchorEl(event.currentTarget);

  const handleCloseModal = () => {
    setLoading(false);
    setOpenModel(false);
    setForm(defaultValues);
  };

  const onChangeForm = field => evt => setForm({
    ...form,
    [field]: evt.target.value
  });

  const handleUpdatePassword = async () => {
    setLoading(true);

    try {
      await apiFetchPut('user/password',form);

      handleCloseModal();
      Alert(
        'success',
        'Sucesso!',
        'Senha alterada com sucesso!'
      );
    } catch (error) {
      if (error.response && error.response.data.message) {
        Alert(
          'warning',
          'Atenção!',
          error.response.data.message
        );
      } else {
        Alert(
          'warning',
          'Atenção!',
          'Houve um erro ao processar a solicitação.'
        );
      }
    }
    setLoading(false);
  };

  return (
    <Fragment>
      <Button color="inherit" onClick={handleClick} className="text-capitalize px-3 text-left btn-inverse d-flex align-items-center">
        <Box>
          <StyledBadge overlap="circle" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">
            <Avatar sizes="44" alt="Dustin Watson" src="" />
          </StyledBadge>
        </Box>
        <div className="d-none d-xl-block pl-3">
          <div className="font-weight-bold pt-2 line-height-1">
            {user.login}
          </div>

        </div>
        <span className="pl-1 pl-xl-3">
          <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
        </span>
      </Button>

      <Menu anchorEl={anchorEl} keepMounted getContentAnchorEl={null} open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        onClose={handleClose}
        className="ml-2">
        <div className="dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
          <List className="text-left bg-transparent d-flex align-items-center flex-column pt-0">
            <Box>
              <StyledBadge overlap="circle"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                variant="dot">
                <Avatar sizes="44" alt="Dustin Watson" src="" />
              </StyledBadge>
            </Box>
            <div className="pl-3 ">
              <span className="text-black-50 text-center">
                {user.login}
              </span>
            </div>
            <Divider className="w-100 mt-2" />
            <ListItem button onClick={handleOpenModal}>Alterar Senha</ListItem>

            <Divider className="w-100" />
            <Divider className="w-100" />
            <ListItem className="d-block rounded-bottom px-3 pt-3 pb-0 text-center">
              <Tooltip arrow title="Sair">
                <Button color="default" className="text-twitter" onClick={handleLogout}>
                  <span className="btn-wrapper--icon">
                    <ExitToAppIcon />
                  </span>
                </Button>
              </Tooltip>
            </ListItem>
          </List>
        </div>
      </Menu>

      <ModalPassword
        {...{
          form,
          loading,
          onChangeForm,
          open: openModal,
          salvar: handleUpdatePassword,
          handleClose: handleCloseModal,
        }}
      />
    </Fragment>
  );
}
