import MuiTheme from './theme';
import { useSelector } from 'react-redux';
import React, { lazy, Suspense } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { userSelector, successSelector } from './store/modules/login/selectors';
import { Switch, Route, Redirect, useLocation, HashRouter } from 'react-router-dom';

import SuspenseLoading from './SuspenseLoading';
import { ThemeProvider } from '@material-ui/styles';
import PagesRegister from './example-pages/PagesRegister';
import PagesError404 from './example-pages/PagesError404';
import PagesError500 from './example-pages/PagesError500';
import PagesError505 from './example-pages/PagesError505';
import { LeftSidebar, MinimalLayout } from './layout-blueprints';
import PagesPassword from './example-pages/PagesRegister/password.js';

//const Update = lazy(() => import('./Pages/Update'));
const Recipient = lazy(() => import('./Pages/Recipient'));
const Coupon = lazy(() => import('./Pages/Registrations/Coupon/ListData'));
const Branch = lazy(() => import('./Pages/Registrations/Branch/ListData'));
const Partner = lazy(() => import('./Pages/Registrations/Partner/ListData'));
const Campaign = lazy(() => import('./Pages/Registrations/Campaign/ListData'));
const Operator = lazy(() => import('./Pages/Registrations/Operator/ListData'));
const Specialty = lazy(() => import('./Pages/Registrations/Specialty/ListData'));
const Consultant = lazy(() => import('./Pages/Registrations/Consultant/ListData'));
const Indication = lazy(() => import('./Pages/Registrations/Indication/ListData'));
const BranchPlan = lazy(() => import('./Pages/Registrations/Branch/Plan/ListData'));
const BranchClient = lazy(() => import('./Pages/Registrations/Branch/Client/ListData'));
const Administrator = lazy(() => import('./Pages/Registrations/Administrator/ListData'));

const Routes = () => {

  const location = useLocation();
  const user = useSelector(userSelector);
  const acesso = useSelector(successSelector);

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };
  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
  };

  return (
    <HashRouter>
      <ThemeProvider theme={MuiTheme}>
        <AnimatePresence>
          <Suspense fallback={<SuspenseLoading />}>
            <Switch>
              {
                !acesso ?
                  <Redirect exact from="/" to="/PagesRegister" />
                  :
                  <Redirect exact from="/" to="/Recipient" />
              }

              <Route path={[
                '/PagesRegister',
                '/PagesPassword',
                '/PagesError404',
                '/PagesError500',
                '/PagesError505',
                '/TwoFA'
              ]}>

                <MinimalLayout>

                  <Switch
                    location={location}
                    key={location.pathname}
                  >
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}
                    >
                      <Route path="/PagesRegister" component={PagesRegister} />
                      <Route path="/PagesPassword" component={PagesPassword} />
                      <Route path="/PagesError404" component={PagesError404} />
                      <Route path="/PagesError500" component={PagesError500} />
                      <Route path="/PagesError505" component={PagesError505} />

                      {!acesso &&
                        <Redirect exact from="/" to="/PagesRegister" />
                      }

                    </motion.div>
                  </Switch>
                </MinimalLayout>
              </Route>

              <Route path={[
                '/User',
                '/ReportMessages',
                '/ReportAttendence',
                '/ReportAgents',
                '/Recipient',
                '/Specialty',
                '/Campaign',
                '/Operator',
                '/Branch',
                '/Plan',
                '/Client',
                '/Coupon',
                '/Indication',
                '/Partner',
                '/Consultant',
                '/Administrator'
              ]}>

                {acesso &&
                  <LeftSidebar restrict >
                    <Switch location={location} key={location.pathname}>
                      <motion.div
                        initial="initial"
                        animate="in"
                        exit="out"
                        variants={pageVariants}
                        transition={pageTransition}
                      >
                        <Route path="/Recipient" component={Recipient} />
                        {user.profile === 'Master' &&
                          <>
                            <Route path="/Specialty" component={Specialty} />
                            <Route path="/Campaign" component={Campaign} />
                            <Route path="/Operator" component={Operator} />
                            <Route path="/Branch" component={Branch} />
                            <Route path="/Plan/:idCompany" component={BranchPlan} />
                            <Route path="/Client/:idCompany" component={BranchClient} />
                            {/* <Route path="/Update" component={Update} /> */}
                            <Route path="/Coupon" component={Coupon} />
                            <Route path="/Indication" component={Indication} />
                            <Route path="/Partner" component={Partner} />
                            <Route path="/Consultant" component={Consultant} />
                            <Route path="/Administrator" component={Administrator} />
                          </>
                        }
                      </motion.div>
                    </Switch>
                  </LeftSidebar>
                }
              </Route>
            </Switch>
          </Suspense>
        </AnimatePresence>
      </ThemeProvider>
    </HashRouter>
  );
};
export default Routes;