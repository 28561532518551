import React, { Fragment } from 'react';

import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { IconButton, Box, Tooltip } from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';

import projectLogo from '../../assets/images/react.svg';

import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import { userSelector } from '../../store/modules/login/selectors';
import { setSidebarToggleMobile, setSidebarToggle } from '../../store/modules/theme/themeSlice';
import { 
  sidebarHoverSelector,
  sidebarToggleSelector,
  sidebarToggleMobileSelector
 } from '../../store/modules/theme/selectors';

const SidebarHeader = props => {
  const dispatch = useDispatch();

  const user = useSelector(userSelector);
  const sidebarHover = useSelector(sidebarHoverSelector);
  const sidebarToggle = useSelector(sidebarToggleSelector);
  const sidebarToggleMobile = useSelector(sidebarToggleMobileSelector);

  const toggleSidebar = () => {
    dispatch(setSidebarToggle(!sidebarToggle));
  };
  const toggleSidebarMobile = () => {
    dispatch(setSidebarToggleMobile(!sidebarToggleMobile));
  };

  return (
    <Fragment>
      <div
        className={clsx('app-sidebar-header', {
          'app-sidebar-header-close': sidebarToggle && !sidebarHover
        })}>
        <Box
          className="header-logo-wrapper"
          title="React Admin Dashboard with Material-UI PRO">
          <Link to="/DashboardDefault" className="header-logo-wrapper-link">
            <IconButton
              color="primary"
              size="medium"
              className="header-logo-wrapper-btn">
              <img
                className="app-sidebar-logo"
                alt="React Admin Dashboard with Material-UI PRO"
                src={projectLogo}
              />
            </IconButton>
          </Link>
          <Box className="header-logo-text">{user.login_user}</Box>
        </Box>
        <Box
          className={clsx('app-sidebar-header-btn', {
            'app-sidebar-header-btn-close': sidebarToggle && !sidebarHover
          })}>
          <Tooltip title="Toggle Sidebar" placement="right">
            <IconButton color="inherit" onClick={toggleSidebar} size="medium">
              {sidebarToggle ? <MenuRoundedIcon /> : <MenuOpenRoundedIcon />}
            </IconButton>
          </Tooltip>
        </Box>
        <Box className="app-sidebar-header-btn-mobile">
          <Tooltip title="Toggle Sidebar" placement="right">
            <IconButton
              color="inherit"
              onClick={toggleSidebarMobile}
              size="medium">
              {sidebarToggleMobile ? (
                <MenuOpenRoundedIcon />
              ) : (
                <MenuRoundedIcon />
              )}
            </IconButton>
          </Tooltip>
        </Box>
      </div>
    </Fragment>
  );
};

export default SidebarHeader;
