import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiFetchGet, apiFetchPut } from "../../../Helpers/RequestResponse";

const initialState = {
    data: {},
    errors: {},
    modal:false,
    idClient: '',
    loading: false,
    success: false,
    isError: false
};

export const getAdress = createAsyncThunk(
    'clientAddress/list',
    async (id_client, { rejectWithValue }) => {
        try {
            const { data } = await apiFetchGet(`client/${id_client}`);

            return data.data.address;
        } catch (error) {
            if (error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('');
            }
        }
    }
);

export const saveAddress = createAsyncThunk(
    'clientAddress/save',
    async ({ data, idClient }, { rejectWithValue }) => {
        console.log(data);

        try {
            await apiFetchPut(`client/${idClient}/address`, data);

        } catch (error) {
            if (error.response.data.messages) {
                return rejectWithValue(error.response.data.messages);
            } else {
                return rejectWithValue('');
            }
        }
    }
);

const clientAddressSlice = createSlice({
    name: 'clientAddress',
    initialState,
    reducers: {
        setModal: (state, action) => {
            state.modal = action.payload.open;
            state.idClient = action.payload.idClient;
        },
        clear: (state, action) => {
            return initialState;
        }
    },
    extraReducers: builder => {
        builder.addCase(getAdress.pending, (state,action) => {
            state.loading = true;
        }).addCase(getAdress.fulfilled, (state, action) => {
            if (action.payload) {
                return {
                    ...state,
                    errors: {},
                    success: true,
                    loading:false,
                    isError: false,
                    data: action.payload
                };
            } else {
                return state;
            }
        }).addCase(getAdress.rejected, (state, action) => {
            return {
                ...state,
                isError: true,
                loading:false,
                success: false,
                errors: action.payload
            };
        });

        builder.addCase(saveAddress.pending, (state,action) => {
            state.loading = true;
        }).addCase(saveAddress.fulfilled, (state, action) => {
            return initialState;
        }).addCase(saveAddress.rejected, (state, action) => {
            return {
                ...state,
                isError: true,
                loading:false,
                success: false,
                errors: action.payload
            };
        });
    }
});

export const {
    clear,
    setModal
} = clientAddressSlice.actions;
export default clientAddressSlice.reducer;