import React, { Fragment, useEffect, useState } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Hidden, Drawer, Paper } from '@material-ui/core';
import SidebarHeader from '../../layout-components/SidebarHeader';
import SidebarMenu from '../../layout-components/SidebarMenu';
import { useSelector } from 'react-redux';
import { userSelector } from '../../store/modules/login/selectors';
import {
  sidebarFixedSelector,
  sidebarFooterSelector,
  sidebarHoverSelector,
  sidebarShadowSelector,
  sidebarToggleMobileSelector,
  sidebarToggleSelector
} from '../../store/modules/theme/selectors';
import { setSidebarToggleMobile, setSidebarHover } from '../../store/modules/theme/themeSlice';

import WorkIcon from '@material-ui/icons/Work';
import FlagIcon from '@material-ui/icons/Flag';
import GroupIcon from '@material-ui/icons/Group';
import BuildIcon from '@material-ui/icons/Build';
import RedeemIcon from '@material-ui/icons/Redeem';
import BusinessIcon from '@material-ui/icons/Business';
import SecurityIcon from '@material-ui/icons/Security';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';

import SidebarFooter from '../../layout-components/SidebarFooter';
//Menus types profile
import { menuAdmin, menuOperator } from './menus';

var iconsMap = { 
  WorkIcon, 
  FlagIcon, 
  GroupIcon, 
  BuildIcon, 
  RedeemIcon,
  BusinessIcon, 
  SecurityIcon,
  LocalOfferIcon,
  CloudUploadIcon, 
  AssignmentIndIcon,
  SupervisedUserCircleIcon
};


const Sidebar = () => {

  const [navItems, setItens] = useState([]);

  const user = useSelector(userSelector);
  const sidebarFixed = useSelector(sidebarFixedSelector);
  const sidebarHover = useSelector(sidebarHoverSelector);
  const sidebarToggle = useSelector(sidebarToggleSelector); 
  const sidebarShadow = useSelector(sidebarShadowSelector);
  const sidebarFooter = useSelector(sidebarFooterSelector);
  const sidebarToggleMobile = useSelector(sidebarToggleMobileSelector);



  const toggleHoverOn = () => setSidebarHover(true);
  const toggleHoverOff = () => setSidebarHover(false);

  const closeDrawer = () => setSidebarToggleMobile(!sidebarToggleMobile);


  useEffect(() => {
    const menu_json = user 
    ?
    JSON.stringify(user.profile === 'Master' ? menuAdmin : menuOperator) 
    : null;

    let generateItens = [{
      label: 'Menu',
      content: JSON.parse(
        user ? menu_json : '[]',
        (key, value) => {
          if (key === 'icon') {
            return iconsMap[value];
          } else {
            return value;
          }
        }
      )
    }];

    setItens(generateItens);

  }, []);

  const sidebarMenuContent = (
    <div
      className={clsx({
        'app-sidebar-nav-close': sidebarToggle && !sidebarHover
      })}>
      {navItems.map(list => (
        <SidebarMenu component="div" key={list.label} pages={list.content} title={list.label} />
      ))}
    </div>
  );

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={sidebarToggleMobile}
          onClose={closeDrawer}
          variant="temporary"
          elevation={4}
          className="app-sidebar-wrapper-lg">
          <SidebarHeader />
          <PerfectScrollbar>
            {sidebarMenuContent}
            {sidebarFooter && <SidebarFooter />}
          </PerfectScrollbar>
        </Drawer>
      </Hidden>

      <Hidden mdDown>
        <Paper
          onMouseEnter={toggleHoverOn}
          onMouseLeave={toggleHoverOff}
          className={clsx('app-sidebar-wrapper', {
            'app-sidebar-wrapper-close': sidebarToggle,
            'app-sidebar-wrapper-open': sidebarHover,
            'app-sidebar-wrapper-fixed': sidebarFixed
          })}
          square
          open={sidebarToggle}
          elevation={sidebarShadow ? 11 : 3}>
          <SidebarHeader />
          <div
            className={clsx({
              'app-sidebar-menu': sidebarFixed,
              'app-sidebar-collapsed': sidebarToggle && !sidebarHover
            })}>
            <PerfectScrollbar options={{ wheelPropagation: false }}>
              {sidebarMenuContent}
              {sidebarFooter && <SidebarFooter />}
            </PerfectScrollbar>
          </div>
        </Paper>
      </Hidden>
    </Fragment>
  );
};

export default Sidebar;