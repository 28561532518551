import React, { Fragment } from 'react';
import clsx from 'clsx';
import { Paper } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { 
  footerFixedSelector,
  footerShadowSelector, 
  sidebarToggleSelector 
} from '../../store/modules/theme/selectors';

const Footer = () => {
  const footerFixed = useSelector(footerFixedSelector);
  const footerShadow = useSelector(footerShadowSelector);
  const sidebarToggle = useSelector(sidebarToggleSelector);
  
  let today = new Date();
  let year = today.getFullYear();

  return (
    <Fragment>
      <Paper
        square
        elevation={footerShadow ? 11 : 2}
        className={clsx('app-footer text-black-50', {
          'app-footer--fixed': footerFixed,
          'app-footer--fixed__collapsed': sidebarToggle
        })}>
        <div className="app-footer--inner">
          <div className="app-footer--second">
            <span>Conheça nossas soluções</span> ©
            {year} - <span className="text-danger px-1">❤</span> by{' '}
            <a href="https://organizacoespower.com.br/" title="UiFort.com">
              Organizações power
            </a>
          </div>
        </div>
      </Paper>
    </Fragment>
  );
};

export default Footer;
