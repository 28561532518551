import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiFecthPost } from "../../../Helpers/RequestResponse";

const initialState = {
    success: false,
    errors: null,
    token: null,
    user: {}
};

export const login = createAsyncThunk(
    'authentication/login',
    async ({ login, password }, { rejectWithValue }) => {
        try {
            const response = await apiFecthPost('auth/login', {
                login,
                password
            });

            return response.data;
        } catch (error) {
            if (error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('');
            }
        }
    }
);

const authenticationSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        clear: (state, action) => {
            return initialState;
        },
        logout: (state, action) => {
            return initialState;
        }
    },
    extraReducers: builder => {
        builder.addCase(login.fulfilled, (state,action) => {
            if (action.payload) {
                return {
                    ...state,
                    success: true,
                    errors: null,
                    token: action.payload.data.token,
                    user: action.payload.data.user
                };
            } else {
                return state;
            }
        }).addCase(login.rejected, (state,action) => {
            return {
                ...state,
                success: false,
                errors: action.payload
            };
        })
    }
});

export const {
    clear,
    logout
} = authenticationSlice.actions;
export default authenticationSlice.reducer;