import React, {
  Fragment,
  useState,
  useEffect
} from 'react';
import clsx from 'clsx';
import {
  Hidden,
  IconButton,
  AppBar,
  Box,
  Tooltip
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import HeaderLogo from '../../layout-components/HeaderLogo';
import HeaderUserbox from '../../layout-components/HeaderUserbox';
import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import HeaderDots from '../HeaderDots';
import HeaderDotsUra from '../HeaderDotsUra';
import { apiFetchGet } from '../../Helpers/RequestResponse';
import { userSelector } from '../../store/modules/login/selectors';
import { setSidebarToggle, setSidebarToggleMobile } from '../../store/modules/theme/themeSlice';
import { 
  headerFixedSelector,
  headerShadowSelector,
  sidebarToggleMobileSelector,
  sidebarToggleSelector
 } from '../../store/modules/theme/selectors';

const Header = props => {
  const dispatch = useDispatch();

  const user = useSelector(userSelector);
  const headerFixed = useSelector(headerFixedSelector);
  const headerShadow = useSelector(headerShadowSelector);
  const sidebarToggle = useSelector(sidebarToggleSelector);
  const sidebarToggleMobile = useSelector(sidebarToggleMobileSelector);


  const toggleSidebar = () => dispatch(setSidebarToggle(!sidebarToggle));
  const toggleSidebarMobile = () => dispatch(setSidebarToggleMobile(!sidebarToggleMobile));


  return (
    <Fragment>
      <AppBar
        color="secondary"
        className={clsx('app-header', {
          'app-header-collapsed-sidebar': props.isCollapsedLayout
        })}
        position={headerFixed ? 'fixed' : 'absolute'}
        elevation={headerShadow ? 11 : 3}>
        {!props.isCollapsedLayout && <HeaderLogo />}
        <Box className="app-header-toolbar">
          <Hidden lgUp>
            <Box
              className="app-logo-wrapper"
              title="React Admin Dashboard with Material-UI PRO">

              <Hidden smDown>

                <Box className="app-logo-text">{user.login_user}</Box>
              </Hidden>
            </Box>
          </Hidden>
          <Hidden mdDown>
            <Box className="d-flex align-items-center">
              {!props.isCollapsedLayout && (
                <Box
                  className={clsx('btn-toggle-collapse', {
                    'btn-toggle-collapse-closed': sidebarToggle
                  })}>
                  <Tooltip title="Toggle Sidebar" placement="right">
                    <IconButton
                      color="inherit"
                      onClick={toggleSidebar}
                      size="medium"
                      className="btn-inverse">
                      {sidebarToggle ? (
                        <MenuRoundedIcon />
                      ) : (
                        <MenuOpenRoundedIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                </Box>
              )}


            </Box>
          </Hidden>
          <Box className="d-flex align-items-center">

            {/* <HeaderDots /> */}

            <HeaderUserbox />
            <Box className="toggle-sidebar-btn-mobile">
              <Tooltip title="Toggle Sidebar" placement="right">
                <IconButton
                  color="inherit"
                  onClick={toggleSidebarMobile}
                  size="medium">
                  {sidebarToggleMobile ? (
                    <MenuOpenRoundedIcon />
                  ) : (
                    <MenuRoundedIcon />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </AppBar>
    </Fragment>
  );
};

export default Header;
