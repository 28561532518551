import React, { Fragment} from 'react';
import { Button, Box} from '@material-ui/core';

export default function SidebarFooter() {

  return (
    <Fragment>
      <Box className="app-sidebar-footer-wrapper">
        <ul className="app-sidebar-footer">
          <li>
              <Button 
                href="https://form.jotform.com/221884331031649" 
                target="_blank" 
              >
                  <b> Solicitar Suporte</b>
              </Button>
          </li>
        </ul>
      </Box>
    </Fragment>
  );
}