import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

export default ({ open, handleClose, salvar, loading, onChangeForm, form }) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth={"lg"}
        >
            <DialogTitle id="form-dialog-title">Alterar Senha</DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item md={12} xs={12}>
                        <TextField
                            onChange={onChangeForm('oldPassword')}
                            value={form.oldPassword}
                            label="Senha Antiga"
                            variant="outlined"
                            type="password"
                            fullWidth
                        />
                    </Grid>

                    <Grid item md={12} xs={12}>
                        <TextField
                            onChange={onChangeForm('password')}
                            value={form.password}
                            label="Nova Senha"
                            variant="outlined"
                            type="password"
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={salvar}
                    disabled={loading ? true : false}
                    variant="contained"
                    color="primary">  {loading ? <CircularProgress color="secondary" /> : 'Salvar'}
                </Button>
                <Button
                    variant="contained"
                    onClick={handleClose}
                    color="secondary">
                    X
                </Button>
            </DialogActions>
        </Dialog>
    )
}