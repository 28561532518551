export const headerFixedSelector = state => state.theme.headerFixed;
export const footerFixedSelector = state => state.theme.footerFixed;
export const sidebarFixedSelector = state => state.theme.sidebarFixed;
export const sidebarHoverSelector = state => state.theme.sidebarHover;
export const headerShadowSelector = state => state.theme.headerShadow;
export const footerShadowSelector = state => state.theme.footerShadow;
export const sidebarToggleSelector = state => state.theme.sidebarToggle;
export const sidebarShadowSelector = state => state.theme.sidebarShadow;
export const sidebarFooterSelector = state => state.theme.sidebarFooter;
export const sidebarUserboxSelector = state => state.theme.sidebarUserbox;
export const pageTitleStyleSelector = state => state.theme.pageTitleStyle;
export const pageTitleShadowSelector = state => state.theme.pageTitleShadow;
export const pageTitleIconBoxSelector = state => state.theme.pageTitleIconBox;
export const contentBackgroundSelector = state => state.theme.contentBackground;
export const headerSearchHoverSelector = state => state.theme.headerSearchHover;
export const pageTitleBackgroundSelector = state => state.theme.pageTitleBackground;
export const sidebarToggleMobileSelector = state => state.theme.sidebarToggleMobile;
export const pageTitleBreadcrumbSelector = state => state.theme.pageTitleBreadcrumb;
export const pageTitleDescriptionSelector = state => state.theme.pageTitleDescription;
export const themeConfiguratorToggleSelector = state => state.theme.themeConfiguratorToggle;