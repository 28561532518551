import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    sidebarShadow: false,
    sidebarFixed: true,
    sidebarToggleMobile: false,
    sidebarFooter: true,
    sidebarUserbox: true,
    sidebarToggle: false,
    sidebarHover: false,
    headerFixed: true,
    headerShadow: true,
    headerSearchHover: false,
    contentBackground: '',
    themeConfiguratorToggle: false,
    footerFixed: false,
    footerShadow: false,
    pageTitleStyle: '',
    pageTitleBackground: '',
    pageTitleShadow: false,
    pageTitleBreadcrumb: false,
    pageTitleIconBox: true,
    pageTitleDescription: true
};

export const themSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        setSidebarShadow: (state,action) => {
            state.sidebarShadow = action.payload;
        },
        setSidebarFixed: (state,action) => {
            state.sidebarFixed = action.payload;
        },
        setSidebarToggleMobile: (state,action) => {
            state.sidebarToggleMobile = action.payload;
        },
        setSidebarFooter: (state,action) => {
            state.sidebarFooter = action.payload;
        },
        setSidebarToggle: (state,action) => {
            state.sidebarToggle = action.payload;
        },
        setSidebarHover: (state,action) => {
            state.sidebarHover = action.payload;
        },
        setSidebarUserbox: (state,action) => {
            state.sidebarUserbox = action.payload;
        },
        setHeaderFixed: (state,action) => {
            state.headerFixed = action.payload;
        },
        setHeaderShadow: (state,action) => {
            state.headerShadow = action.payload;
        },
        setHeaderSearchHover: (state,action) => {
            state.headerSearchHover = action.payload;
        },
        setContentBackground: (state,action) => {
            state.contentBackground = action.payload;
        },
        setThemeConfiguratorToggle: (state,action) => {
            state.themeConfiguratorToggle = action.payload;
        },
        setFooterFixed: (state,action) => {
            state.footerFixed = action.payload;
        },
        setFooterShadow: (state,action) => {
            state.footerShadow = action.payload;
        },
        setPageTitleStyle: (state,action) => {
            state.pageTitleStyle = action.payload;
        },
        setPageTitleBackground: (state,action) => {
            state.pageTitleBackground = action.payload;
        },
        setPageTitleShadow: (state,action) => {
            state.pageTitleShadow = action.payload;
        },
        setPageTitleBreadcrumb: (state,action) => {
            state.pageTitleBreadcrumb = action.payload;
        },
        setPageTitleIconBox: (state,action) => {
            state.pageTitleIconBox = action.payload;
        },
        setPageTitleDescription: (state,action) => {
            state.pageTitleDescription = action.payload;
        },
    }
});

export const {
    setContentBackground,
    setFooterFixed,
    setFooterShadow,
    setHeaderFixed,
    setHeaderSearchHover,
    setHeaderShadow,
    setPageTitleBackground,
    setPageTitleBreadcrumb,
    setPageTitleDescription,
    setPageTitleIconBox,
    setPageTitleShadow,
    setPageTitleStyle,
    setSidebarFixed,
    setSidebarFooter,
    setSidebarHover,
    setSidebarShadow,
    setSidebarToggle,
    setSidebarToggleMobile,
    setSidebarUserbox,
    setThemeConfiguratorToggle
} = themSlice.actions;
export default themSlice.reducer;