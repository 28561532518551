import React from 'react';
import { ClimbingBoxLoader } from 'react-spinners';

const SuspenseLoading = () => {
    return (
      <>
        <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
          <div className="d-flex align-items-center flex-column px-4">
            <ClimbingBoxLoader color={'#5383ff'} loading={true} />
          </div>
          <div className="text-muted font-size-xl text-center pt-3">
            Carregando...
            <span className="font-size-lg d-block text-dark">
              Aguarde um momento!
            </span>
          </div>
        </div>
      </>
    );
  };
export default SuspenseLoading;