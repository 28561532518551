import React, { Fragment} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {Paper,Box,Typography} from '@material-ui/core';
import DashboardTwoToneIcon from '@material-ui/icons/DashboardTwoTone';
import { useSelector } from 'react-redux';
import {
  pageTitleBackgroundSelector,
  pageTitleDescriptionSelector,
  pageTitleIconBoxSelector,
  pageTitleShadowSelector,
  pageTitleStyleSelector,
} from '../../store/modules/theme/selectors';


function TabPanel({children, value, index, ...other}) {
  return (
    <Typography component="div" role="tabpanel"  hidden={value !== index} {...other}>
      {value === index && <Box p={4}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};


function PageTitle(props) {

  const pageTitleStyle = useSelector(pageTitleStyleSelector);
  const pageTitleShadow = useSelector(pageTitleShadowSelector);
  const pageTitleIconBox = useSelector(pageTitleIconBoxSelector);
  const pageTitleBackground = useSelector(pageTitleBackgroundSelector);
  const pageTitleDescription = useSelector(pageTitleDescriptionSelector);

return (
    <Fragment>
      <Paper
        square
        elevation={pageTitleShadow ? 6 : 2}
        className={clsx('app-page-title', pageTitleStyle, pageTitleBackground)}>
        <div>
           <Box className="app-page-title--first">
            {pageTitleIconBox && (
              <Paper
                elevation={2}
                className="app-page-title--iconbox d-70 d-flex align-items-center bg-secondary justify-content-center">
                <DashboardTwoToneIcon />
              </Paper>
            )}
            <div className="app-page-title--heading">
              <h1>{props.titleHeading}</h1>
              {pageTitleDescription && (
                <div className="app-page-title--description">
                  {props.titleDescription}
                </div>
              )}
            </div>
          </Box>
        </div>
      </Paper>
    </Fragment>
  );
}

export default PageTitle;
