import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography
} from '@material-ui/core';

export default function TabPanel({ children,value,index,...other }) {
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        {...other}>
        {value === index && <Box p={0}>{children}</Box>}
      </Typography>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
  };