import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MuiAlert from '@material-ui/lab/Alert';

export default function Warning({message,color}){

    let fontColor = `${color ? "#fff": '#000'}`;

    return(
        <MuiAlert variant="outlined" className="alerts-alternate mb-4" color="warning">
        <div className="d-flex align-items-center align-content-start">
          <span className="font-size-lg d-block d-40 mr-3 text-center bg-warning text-white rounded-sm">
            <FontAwesomeIcon icon={['far', 'question-circle']} />
          </span>
          <span>
            <strong 
              className="d-block" 
              style={{color:fontColor }}>Atenção!</strong> 
               <p style={{color: fontColor }}>{message}</p> 
          </span>
        </div>
      </MuiAlert>
    )
}