import React, { Fragment } from 'react';
import clsx from 'clsx';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import {userSelector} from '../../store/modules/login/selectors';
import { sidebarHoverSelector, sidebarToggleSelector } from '../../store/modules/theme/selectors';

const HeaderLogo =() => {

  const user = useSelector(userSelector);
  const sidebarHover = useSelector(sidebarHoverSelector);
  const sidebarToggle = useSelector(sidebarToggleSelector);
 
  return (
    <Fragment>
      <div
        className={clsx('app-header-logo', {
          'app-header-logo-close': sidebarToggle,
          'app-header-logo-open': sidebarHover
        })}>
        <Box className="header-logo-wrapper">
            <Box className="header-logo-text" style={{fontSize:'12px'}}>{user.name_company}</Box>
        </Box>
      </div>
    </Fragment>
  );
};

export default HeaderLogo;
