import React, { Fragment,useState,useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {Redirect} from 'react-router-dom'
import { Sidebar, Header, Footer } from '../../layout-components';
import { useSelector } from 'react-redux';
import {tokenSelector} from '../../store/modules/login/selectors';
import {
  footerFixedSelector,
  contentBackgroundSelector,
  sidebarToggleSelector,
  sidebarFixedSelector
} from '../../store/modules/theme/selectors';



const LeftSidebar = ({children,restrict= false}) => {

  const token = useSelector(tokenSelector);
  const sidebarToggle = useSelector(sidebarToggleSelector);
  const sidebarFixed = useSelector(sidebarFixedSelector);
  const footerFixed = useSelector(footerFixedSelector);
  const contentBackground = useSelector(contentBackgroundSelector);

  if(!token && !restrict) return <Redirect to="PagesRegister" />

  return (
    <Fragment>
      <div className={clsx('app-wrapper', contentBackground)}>
        <Header />
        <div className={clsx('app-main', {'app-main-sidebar-static': !sidebarFixed})}>
            {token && restrict ?  <Sidebar /> : <Redirect to="PagesRegister" />}
          <div
            className={clsx('app-content', {
              'app-content-sidebar-collapsed': sidebarToggle,
              'app-content-sidebar-fixed': sidebarFixed,
              'app-content-footer-fixed': footerFixed
            })}>
            <div className="app-content--inner">
              <div className="app-content--inner__wrapper">{children}</div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

LeftSidebar.propTypes = {
  children: PropTypes.node
};

export default LeftSidebar;
