import React, { useState} from 'react';
import {apiFetchPut} from '../../Helpers/RequestResponse';
import Alert from 'sweetalert2';
import { Container, Form, RedBackdrop, InputGroup, Title, Label, Input, Button, Banner } from './styles';
import {logout} from '../../Helpers/RequestResponse'


function AlterarSenha() {


    const [password, setPasswordV]                        = useState('');
    const [first_access,setFirsAccess]                    = useState(0);
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const[view,setView]                                   = useState(true)
    
    async function alterarSenha() {
        if (password !== passwordConfirmation)
            return Alert.fire('Erro', 'As senhas precisam ser iguais');

            try {

            await apiFetchPut('/password-user', {password,first_access });
            setView(false);
            } catch (error) {
                console.log(error.response);
                Alert.fire('Erro', error.response.data.error);
                setView(true)
            }
    }

    const  handlelogin = ()  =>  logout();
    


    return (
        <Container>
            <Banner src="https://neema.ai/neema/wp-content/uploads/2019/02/chatbot.png" />
            <RedBackdrop />
            <Form>
                {view &&
                    <>
                        <Title>Alterar Senha</Title>
                        <InputGroup>
                            <Label>Nova Senha</Label>
                            <Input type='oldPassword' onChange={e => setPasswordV(e.target.value)} />
                        </InputGroup>
                        <InputGroup>
                            <Label>Confirmar nova Senha</Label>
                            <Input type='oldPassword' onChange={e => setPasswordConfirmation(e.target.value)} />
                        </InputGroup>
                        <Button onClick={alterarSenha}>ALTERAR</Button>
                    </>
                }
               
                {!view && <Button style={{background:'blue'}} onClick={handlelogin}>FAZER LOGIN</Button>}
               
            </Form>
        </Container>
    );
}

export default AlterarSenha;
