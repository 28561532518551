export const menuAdmin = [
    {
        "label": "Beneficiário",
        "icon": "GroupIcon",
        "description": "Beneficiário",
        "to": "Recipient"
    },
    {
        "to": "Specialty",
        "icon": "WorkIcon",
        "label": "Especialidades",
        "description": "Especialidades"
    },
    {
        "to": "Campaign",
        "icon": "FlagIcon",
        "label": "Campanhas",
        "description": "Campanhas"
    },
    {
        "to": "Administrator",
        "icon": "SecurityIcon",
        "label": "Administradores",
        "description": "Administradores"
    },
    {
        "to": "Operator",
        "icon": "BuildIcon",
        "label": "Operadores",
        "description": "Operadores"
    },
    {
        "to": "Branch",
        "icon": "BusinessIcon",
        "label": "Filiais",
        "description": "Filiais"
    },
    // {
    //     "to": "Update",
    //     "label": "Atualizações",
    //     "icon": "CloudUploadIcon",
    //     "description": "Atualizações"
    // },
    {
        "to": "Coupon",
        "icon": "LocalOfferIcon",
        "label": "Cupom parceiros",
        "description": "Cupom parceiros"
    },
    {
        "to": "Indication",
        "icon": "RedeemIcon",
        "label": "Indicações",
        "description": "Indicações"
    },
    {
        "to": "Partner",
        "label": "Parceiros",
        "description": "Parceiros",
        "icon": "SupervisedUserCircleIcon"
    },
    {
        "to": "Consultant",
        "label": "Consultores",
        "icon": "AssignmentIndIcon",
        "description": "Consultores"
    }
];

export const menuOperator = [
    {
        "label": "Beneficiário",
        "icon": "GroupIcon",
        "description": "Beneficiário",
        "to": "Recipient"
    }
];