import '../assets/global.scss';
import Swal from 'sweetalert2';

const dateFormater = new Intl.DateTimeFormat('pt-BR', {
  dateStyle: 'short',
  timeStyle: 'short'
});

export const formatDate = (value) => dateFormater.format(new Date(value))

export const LimitString = (data) => {
  if (data.length > 20)
    return data.substring(0, 15)

  return data;
};

export const Alert = (type, title, text, options = {}) => Swal.fire({
  ...options,
  type,
  title,
  text,
  customClass: {
    container: 'my-swal'
  }
});