import { combineReducers } from 'redux';

import theme from './theme/themeSlice';
import authentication from './login/authSlice';
import clientAddress from './clientAddress/addressSlice';

const rootReducer = combineReducers({
    theme,authentication,clientAddress
});

export default rootReducer;