import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`;

export const RedBackdrop = styled.div`
    position: absolute;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .3);
`;

export const Banner = styled.img`
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    object-fit: cover;
`;

export const Form = styled.div`
    width: 450px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 2px 3px rgba(0, 0, 0, .14);
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 15px;
    margin-top:180px;
`;

export const Title = styled.span`
    font-weight: bold;
    font-size: 22px;
    color:white;
`;

export const InputGroup = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

export const Label = styled.span`
    font-size: 12px;
    color:white;
`;

export const Input = styled.input`
    width: 100%;
    height: 40px;
    padding: 0 10px;
`;

export const Button = styled.button`
    border: none;
    outline: none;
    width: 100%;
    height: 40px;
    border-radius: 4px;
    background: ${props => props.clean ? 'white' : 'orange'};
    color: ${props => props.clean ? 'black' : 'white'};
    font-size: 12px;    
    font-weight: bold;

    &:focus {
        outline:0;
    }
`;


