import React, { Fragment } from 'react';

import PagesRegisterContent from '../../example-components/PagesRegister/PagesRegisterContent';
export default function PagesRegister() {

  return (
    <Fragment>
      <PagesRegisterContent />
    </Fragment>
  );
}
